import { useEffect, useRef, useState } from 'react'
import styles from './paymentDetails.module.scss'
import Image from 'next/image'
import { useRecoilState } from 'recoil'
import { subscriptionSignUpState } from '../../recoil/atoms'
import { loadStripe } from '@stripe/stripe-js'
import {
  BUSINESSSUB_PERKS,
  STANDARDSUB_PERKS,
  STARTERSUB_PERKS,
} from '../../data/subPerks'
import { Elements } from '@stripe/react-stripe-js'
import CardPaymentForm from '../../components/CardPaymentForm/CardPaymentForm'
import { useSession } from 'next-auth/react'
import {
  getSubscriptionData,
  updateStartedCheckout,
} from '../../services/profileHelper'
import CouponField from '../CouponField/CouponField'
import { createSubscripctions } from '../../services/stripeCallsHelper'
import LoadingSpinner from '../Loader/Loader'

import stylesSignUp from '@styles/signup.module.scss'
import StyledTooltip from '@components/StyledTooltip/StyledTooltip'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LoopIcon from '@mui/icons-material/Loop'
import BlockIcon from '@mui/icons-material/Block'
import { SubscriptionType as SubType } from '@prisma/client'
import { useRouter } from 'next/router'
import axios from 'axios'
import { SUBSCRIPTION_TYPES } from '../../utils/consts'

type Props = {
  isSignUp: boolean
  isCouponEnabled?: boolean
}

export default function PaymentDetails({
  isSignUp,
  isCouponEnabled = false,
}: Props) {
  const { status } = useSession()
  const [subsSignUpData, setSubSingUpData] = useRecoilState(
    subscriptionSignUpState
  )

  const [clientSecret, setClientSecretState] = useState()
  const stripePromise = loadStripe(
    process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY || ''
  )

  const [planType, setPlanType] = useState<any>(null)
  const [isAnnual, setIsAnnual] = useState<boolean>(false)
  const [perks, setPerks] = useState<any>(null)
  const [isCouponState, setIsCouponState] = useState(isCouponEnabled)
  const [coupon, setCoupon] = useState('')
  const [isLoading, setIsloading] = useState(true)

  const isFirstMount = useRef(true)
  useEffect(() => {
    if (isFirstMount.current) {
      if (!isCouponEnabled && !nextStepState) {
        nextStep()
      }
      if (status === 'authenticated') {
        updateStartedCheckout()
      }
    }
  }, [])

  useEffect(() => {
    if (subsSignUpData.type.toLowerCase() === 'starter') {
      setPerks(STARTERSUB_PERKS)
    }
    if (subsSignUpData.type.toLowerCase() === 'basic') {
      setPerks(STANDARDSUB_PERKS)
    }
    if (subsSignUpData.type.toLowerCase() === 'premium') {
      setPerks(BUSINESSSUB_PERKS)
    }
  }, [subsSignUpData.type])

  const appearance = {
    variables: {
      colorText: '#000000',
      fontFamily: '"Montserrat", sans-serif',
      border: '1px solid #E0E0E0',
      borderRadius: '12px',
      colorIconTab: 'transparent',
    },
    rules: {
      '.TermsText': {
        fontWeight: '400',
        fontFamily: '"Montserrat", sans-serif',
        color: '#828282',
        fontSize: '14px',
      },
    },
  }

  const appliedCoupon = (id?: string) => {
    if (id) {
      setCoupon(id)
    }
  }

  const [subscription, setSubscription] = useState<any>({
    subscriptionId: null,
    clientSecret: null,
    billingDate: null,
    amount: null,
    price: null,
  })

  const router = useRouter()

  useEffect(() => {
    async function updateInitPlan(initPlan: any) {
      await axios.put(`/api/user/initPlan`, {
        initPlan: initPlan,
      })
    }
    const initPlan = router.query.initPlan
    if (initPlan) {
      updateInitPlan(initPlan)
    }
  }, [router.query.initPlan])

  const [nextStepState, setNextStepState] = useState(false)
  // COUPON functionality
  const nextStep = async () => {
    setNextStepState(true)
    let customerStripeId = subsSignUpData.customerStripeId
    let subPriceId = subsSignUpData.priceId
    setPlanType(subsSignUpData.type)
    setIsAnnual(subsSignUpData.annual)

    if (!subsSignUpData.customerStripeId.length) {
      const priceId = localStorage.getItem('priceId')
      let type: string | undefined | null = localStorage.getItem('planType')
      const annual = localStorage.getItem('planIsAnnual')
      const subsData = await getSubscriptionData()
      customerStripeId = subsData.stripeCustomerID
      subPriceId = priceId || ''
      if (
        !subPriceId.length &&
        subsData.activePlan === SUBSCRIPTION_TYPES.FREE.id &&
        subsData.initPlan
      ) {
        const initSubscriptionData = SUBSCRIPTION_TYPES[subsData.initPlan]
        if (initSubscriptionData) {
          type = initSubscriptionData.id
          const subscriptionProds = initSubscriptionData.prodId
          if (subscriptionProds) {
            const productId =
              subscriptionProds[annual === 'true' ? 'annual' : 'monthly']
            const products = await axios.get('/api/stripe/getProducts')
            subPriceId = products.data.data.find(
              (data: any) => data.id === productId
            ).default_price
          }
        }
      }
      setPlanType(type)
      setIsAnnual(annual === 'true')

      setSubSingUpData({
        ...subsSignUpData,
        customerStripeId: customerStripeId,
        type: type as SubType,
        annual: annual === 'true',
        priceId: subPriceId,
      })
    }
    try {
      const referral = window.promotekit_referral
      const sub = await createSubscripctions(
        customerStripeId,
        subPriceId,
        coupon,
        referral
      )
      setClientSecretState(sub.clientSecret)
      setSubscription(sub)
      setSubSingUpData({
        ...subsSignUpData,
        subscriptionId: sub.subscriptionId,
      })
      setIsCouponState(false)
      setIsloading(false)
    } catch (err) {
      console.error(err)
      setNextStepState(false)
    }
  }

  const today = function () {
    const date = new Date()
    return date.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    })
  }

  const capitalizeFirstLetter = (text: string) => {
    text = text.toLowerCase()
    return text.charAt(0).toUpperCase() + text.slice(1)
  }
  return (
    <div className={stylesSignUp.wrapper}>
      <div className={stylesSignUp.form_width}>
        <h2 className={stylesSignUp.top_text}>Complete your account</h2>
        {isCouponEnabled && (
          <div
            className={
              isCouponState
                ? styles.active_step_title
                : styles.inactive_step_title
            }
          >
            Apply Coupon
          </div>
        )}
        {isCouponState && isCouponEnabled && (
          <div className={styles.coupon}>
            <div className={styles.coupon_box}>
              <CouponField handleCouponSuccess={appliedCoupon} />
              <button
                onClick={isCouponEnabled ? nextStep : () => {}}
                className={styles.btn}
                disabled={nextStepState}
              >
                {coupon === '' ? 'skip' : 'continue'}
              </button>
            </div>
          </div>
        )}
        {!isSignUp && (
          <div
            className={
              !isCouponState
                ? styles.active_step_title
                : styles.inactive_step_title
            }
          >
            Make Payment
          </div>
        )}
        {isLoading && !isCouponEnabled && (
          <div
            style={{
              minWidth: '650px',
            }}
          >
            <LoadingSpinner />
          </div>
        )}

        {!isCouponState && !isLoading && (
          <div className={styles.payment}>
            {!isSignUp && (
              <div className={styles.payment_text}>
                {capitalizeFirstLetter(subsSignUpData.type)} Plan –{' '}
                {subsSignUpData.annual ? ' Yearly' : 'Monthly'}
              </div>
            )}

            <div className={styles.content}>
              <div className={styles.column}>
                <Elements
                  stripe={stripePromise}
                  options={{ clientSecret, appearance }}
                >
                  <CardPaymentForm
                    plan={planType}
                    isAnnual={isAnnual}
                    billingDate={String(subscription.billingDate)}
                    subscriptionId={subscription.subscriptionId}
                    isSignUp={isSignUp}
                  />
                </Elements>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={stylesSignUp.graybox}>
        {!isCouponState && !isLoading && (
          <>
            <div className={stylesSignUp.title}>Plan summary</div>
            <div className={styles.planDetails}>
              <div className={styles.price}>
                ${subscription.amount}
                <span className={styles.pricePeriod}>
                  {isAnnual ? ' / y.' : ' / mo.'}
                </span>
              </div>
              <div className={styles.backGuarantee}>
                30 day money back guarantee, cancel anytime.
              </div>
              {perks &&
                perks.map((perk: any, index: number) => (
                  <div>
                    {perk.tooltip ? (
                      <StyledTooltip content={perk.tooltip}>
                        <div className={styles.subPerks}>
                          <div className={styles.infoCheck}>
                            <Image
                              alt="check"
                              src="/assets/check_green.svg"
                              width={20}
                              height={20}
                            />
                          </div>
                          <p className={styles.infoText}>{perk.desc}</p>
                        </div>
                      </StyledTooltip>
                    ) : (
                      <div className={styles.subPerks}>
                        <div className={styles.infoCheck}>
                          <Image
                            alt="check"
                            src="/assets/check_green.svg"
                            width={20}
                            height={20}
                          />
                        </div>
                        <p className={styles.infoText}>{perk.desc}</p>
                      </div>
                    )}
                  </div>
                ))}
              <div className={styles.paymentDetails}>
                <div className={styles.paymentDetailRow}>
                  <CalendarTodayIcon className={styles.paymentDetailIcon} />
                  First payment on {today()}
                </div>
                <div className={styles.paymentDetailRow}>
                  <LoopIcon className={styles.paymentDetailIcon} />
                  Recurring fee: ${subscription.amount}
                </div>
                <div className={styles.paymentDetailRow}>
                  <BlockIcon className={styles.paymentDetailIcon} />
                  Cancel anytime, hassle free
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
