import axios from 'axios'
import { SubscriptionType as SubType } from '@prisma/client'
import { getTagId } from '../utils/convertKitConsts'

export const addConvertKitTag = async (
  email: string,
  newSubsciptionType: SubType | 'guest'
) => {
  const tagId = getTagId(newSubsciptionType)
  await axios.post(
    `/api/convertkit/subscribeToTag?email=${email}&tagId=${tagId}`
  )
}

export const removeConvertKitTag = async (
  email: string,
  oldSubsciptionType: SubType
) => {
  const tagId = getTagId(oldSubsciptionType)
  await axios.post(`/api/convertkit/removeTag?email=${email}&tagId=${tagId}`)
}

export const changeConvertKitTag = async (
  email: string,
  oldSubsciptionType: SubType,
  newSubsciptionType: SubType
) => {
  await removeConvertKitTag(email, oldSubsciptionType)
  await addConvertKitTag(email, newSubsciptionType)
}

export const subscribeToFormWithTag = async (
  formId: number,
  email: string,
  tagName: SubType | 'guest',
  firstName: string
): Promise<void> => {
  const tagId = getTagId(tagName)
  await axios.post(`/api/convertkit/subscribeToForm`, {
    formId,
    email,
    tagId,
    firstName,
  })
}
