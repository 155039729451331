import React from 'react'
import styles from './loader.module.scss'

type Props = {
  height?: string
  small?: boolean
}

export default function LoadingSpinner({
  height = '350px',
  small = false,
}: Props) {
  return (
    <div className={styles.spinner_container} style={{ height: height }}>
      <div
        className={
          small ? styles.small_loading_spinner : styles.loading_spinner
        }
      ></div>
    </div>
  )
}
