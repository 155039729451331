import React, { useEffect, useState } from 'react'
import { SubscriptionType as SubType } from '@prisma/client'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import styles from './cardPayment.module.scss'
import LoadingSpinner from '../Loader/Loader'
import { SubscriptionData } from '@prisma/client'
import { getUser, updateSubscriptionData } from '../../services/profileHelper'
import { changeConvertKitTag } from '../../services/convertKitHelper'
import { SUBSCRIPTION_TYPES } from '../../utils/consts'
import { useRouter } from 'next/router'
import { useSetRecoilState } from 'recoil'
import { profileState } from '../../recoil/atoms'

type Props = {
  subscriptionId: string
  plan: SubType
  isAnnual: boolean
  billingDate: string
  isSignUp: boolean
}

export default function CardPaymentForm({
  subscriptionId,
  plan,
  isAnnual,
  billingDate,
  isSignUp,
}: Props) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const setProfile = useSetRecoilState(profileState)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )
    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/dashboard',
      },
      redirect: 'if_required',
    })

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        if (error.message) {
          setMessage(error.message)
        }
      } else {
        setMessage('An unexpected error occurred.')
      }
      setIsLoading(false)
    } else {
      updateSubscription()
    }
  }

  const router = useRouter()
  const updateSubscription = async () => {
    const subscriptionUpdateQuery: Partial<SubscriptionData> = {
      subscriptionId: subscriptionId,
      activePlan: plan,
      isAnnual: isAnnual,
      nextPlan: plan,
      nextBillingDate: billingDate,
      referral: window.promotekit_referral,
    }

    const updatedSubscriptionData = await updateSubscriptionData(
      subscriptionUpdateQuery
    )

    const user = await getUser()

    if (user) {
      setProfile(user)
      changeConvertKitTag(user.email, SUBSCRIPTION_TYPES.FREE.id, plan)
    }

    if (isSignUp) {
      router.push('/dashboard')
    } else {
      router.push('/dashboard?payment=true')
    }
  }

  return (
    <div>
      <form id="payment-form" onSubmit={handleSubmit}>
        {/*<ExpressCheckoutElement id="express-payment-element"  onConfirm={() => { console.log('Y')}}/>*/}
        <PaymentElement id="payment-element" />
        <div className={styles.moneyBackGuarantee}>
          30 day money back guarantee, cancel anytime.
        </div>
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className={styles.btn}
        >
          <span id="button-text">
            {isLoading ? (
              <LoadingSpinner height="20px" small={true} />
            ) : (
              <div>{isSignUp ? 'View Dashboard' : 'Subscribe'}</div>
            )}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
        <div className={styles.footer}>
          <div className={styles.element}>
            Powered by
            <a className={styles.stripeLogo} href={'https://stripe.com/'}>
              Stripe
            </a>
          </div>
          <div className={styles.elementRight}>
            <a href={'/about/terms-of-service'}>Terms</a>
            <a href={'/about/privacy-policy'}>Privacy</a>
          </div>
        </div>
      </form>
    </div>
  )
}
