import { FormEvent, useEffect, useState } from 'react'
import couponStyles from './couponField.module.scss'
import { addCouponCode, getCouponCodes } from '../../services/stripeCallsHelper'

type Props = {
  subscriptionID?: string
  handleCouponSuccess?: (id?: string) => void
  isSettings?: boolean
}

export default function CouponField({
  subscriptionID,
  handleCouponSuccess,
  isSettings,
}: Props) {
  const [coupon, setCoupon] = useState('')
  const [isCouponSuccess, setIsCouponSuccess] = useState<boolean | null>(null)
  const isCouponUsed = typeof isCouponSuccess === 'boolean'

  useEffect(() => {
    if (coupon === '') {
      setIsCouponSuccess(null)
    }
  }, [coupon])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    getPromotionCode(coupon, subscriptionID)
  }

  const getPromotionCode = async (coupon: string, subscriptionID?: string) => {
    try {
      const codes = await getCouponCodes(coupon)
      if (codes.length > 0) {
        if (subscriptionID) {
          await addCouponCode(subscriptionID, codes[0].coupon.id)
          if (handleCouponSuccess) {
            handleCouponSuccess()
          }
        } else {
          if (handleCouponSuccess) {
            handleCouponSuccess(codes[0].coupon.id)
          }
        }
        setIsCouponSuccess(true)
      } else {
        setIsCouponSuccess(false)
      }
    } catch (error) {
      console.error(error)
      setIsCouponSuccess(false)
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={
        isSettings
          ? couponStyles.settings_field
          : subscriptionID
          ? couponStyles.coupon_field
          : couponStyles.signup_coupon_field
      }
    >
      <div className={couponStyles.inputs}>
        <input
          className={
            isCouponUsed
              ? isCouponSuccess
                ? `${couponStyles.applied_coupon} ${couponStyles.coupon_input}`
                : `${couponStyles.wrong_coupon} ${couponStyles.coupon_input}`
              : couponStyles.coupon_input
          }
          type="text"
          placeholder="Add coupon code here"
          value={coupon}
          onChange={(e) => {
            setCoupon(e.target.value)
            setIsCouponSuccess(null)
          }}
          id="couponInput"
        />
        {isCouponUsed && (
          <label
            htmlFor="couponInput"
            className={`${couponStyles.inputLabel} ${
              isCouponSuccess ? couponStyles.applied : couponStyles.invalid
            }`}
          >
            {isCouponSuccess ? 'Coupon Applied!' : 'Invalid Coupon!'}
          </label>
        )}
      </div>

      <button
        type="submit"
        className={
          isCouponUsed
            ? isCouponSuccess
              ? `${couponStyles.coupon_button} ${couponStyles.applied_coupon} ${couponStyles.coupon_button_active}`
              : `${couponStyles.coupon_button} ${couponStyles.wrong_coupon}  ${couponStyles.coupon_button_active}`
            : coupon === ''
            ? couponStyles.coupon_button
            : `${couponStyles.coupon_button} ${couponStyles.coupon_button_active}`
        }
      >
        Apply
      </button>
    </form>
  )
}
