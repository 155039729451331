import React, { ReactElement, ReactNode } from 'react'
import { Tooltip } from '@mui/material'
import styles from './styledTooltip.module.scss'

type Props = {
  children: ReactElement
  type?: 'default' | 'error' | 'clickable'
  position?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  content: NonNullable<ReactNode> | undefined
  followCursor?: boolean
  arrow?: boolean
  open?: boolean
  clickMode?: boolean
}

function StyledTooltip({
  children,
  position = 'bottom',
  type = 'default',
  content,
  followCursor = false,
  arrow = false,
  open,
  clickMode = false,
}: Props) {
  if (content) {
    return (
      <Tooltip
        open={open}
        disableInteractive
        disableHoverListener={clickMode}
        followCursor={followCursor}
        placement={position}
        classes={{
          popper: `${styles.tooltip} ${styles[type]}`,
          arrow: styles.arrow,
        }}
        title={
          type === 'default' && content !== '' ? (
            <div className={styles.content}>{content}</div>
          ) : (
            content
          )
        }
        arrow={arrow}
        PopperProps={{ style: { zIndex: 10000 } }}
      >
        {children}
      </Tooltip>
    )
  } else {
    return children
  }
}

export default StyledTooltip
