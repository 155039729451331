import { SubscriptionType as SubType } from '@prisma/client'
import { SUBSCRIPTION_TYPES } from './consts'

export const tagIds = {
  guest: '3152397',
  waitlist: '3232790',
  free: '3152396',
  standard: '3305480',
  business: '3305481',
  youtube: '3444359',
}

export const getTagId = (priceType: SubType | 'guest' | 'waitlist'): string => {
  switch (priceType) {
    case 'guest':
      return tagIds.guest
    case 'waitlist':
      return tagIds.waitlist
    case SUBSCRIPTION_TYPES.FREE.id:
      return tagIds.free
    case SUBSCRIPTION_TYPES.PREMIUM.id:
      return tagIds.business
    case SUBSCRIPTION_TYPES.BASIC.id:
      return tagIds.standard
    default:
      return tagIds.free
  }
}
